const degree = [
  45, 35, 70, 80, 40, 30, 70, 80, 35, 25, 70, 80, 30, 20, 70, 80, 25, 15, 70,
  80, 20, 10, 70, 80, 15, 5, 70, 80, 10, 0, 70, 80, 5, -5, 70, 80, 0, -10, 70,
  80, -5, -15, 70, 80, -10, -20, 70, 80, -15, -25, 70, 80, -20, -30, 70, 80,
  -25, -35, 70, 80, -30, -40, 70, 80, -35, -45, 70, 80, 45, 35, 75, 85, 40, 30,
  75, 85, 35, 25, 75, 85, 30, 20, 75, 85, 25, 15, 75, 85, 20, 10, 75, 85, 15, 5,
  75, 85, 10, 0, 75, 85, 5, -5, 75, 85, 0, -10, 75, 85, -5, -15, 75, 85, -10,
  -20, 75, 85, -15, -25, 75, 85, -20, -30, 75, 85, -25, -35, 75, 85, -30, -40,
  75, 85, -35, -45, 75, 85, 45, 35, 80, 90, 40, 30, 80, 90, 35, 25, 80, 90, 30,
  20, 80, 90, 25, 15, 80, 90, 20, 10, 80, 90, 15, 5, 80, 90, 10, 0, 80, 90, 5,
  -5, 80, 90, 0, -10, 80, 90, -5, -15, 80, 90, -10, -20, 80, 90, -15, -25, 80,
  90, -20, -30, 80, 90, -25, -35, 80, 90, -30, -40, 80, 90, -35, -45, 80, 90,
  45, 35, 85, 95, 40, 30, 85, 95, 35, 25, 85, 95, 30, 20, 85, 95, 25, 15, 85,
  95, 20, 10, 85, 95, 5, -5, 85, 95, 0, -10, 85, 95, -5, -15, 85, 95, -10, -20,
  85, 95, -15, -25, 85, 95, -20, -30, 85, 95, -25, -35, 85, 95, -30, -40, 85,
  95, -35, -45, 85, 95, 45, 35, 90, 100, 40, 30, 90, 100, 35, 25, 90, 100, 30,
  20, 90, 100, 25, 15, 90, 100, 20, 10, 90, 100, 15, 5, 90, 100, 10, 0, 90, 100,
  5, -5, 90, 100, 0, -10, 90, 100, -5, -15, 90, 100, -10, -20, 90, 100, -15,
  -25, 90, 100, -20, -30, 90, 100, -25, -35, 90, 100, -30, -40, 90, 100, -35,
  -45, 90, 100, 45, 35, 95, 105, 40, 30, 95, 105, 35, 25, 95, 105, 30, 20, 95,
  105, 25, 15, 95, 105, 20, 10, 95, 105, 15, 5, 95, 105, 10, 0, 95, 105, 5, -5,
  95, 105, 0, -10, 95, 105, -5, -15, 95, 105, -10, -20, 95, 105, -15, -25, 95,
  105, -20, -30, 95, 105, -25, -35, 95, 105, -30, -40, 95, 105, -35, -45, 95,
  105, 45, 35, 100, 110, 40, 30, 100, 110, 35, 25, 100, 110, 30, 20, 100, 110,
  25, 15, 100, 110, 20, 10, 100, 110, 15, 5, 100, 110, 10, 0, 100, 110, 5, -5,
  100, 110, 0, -10, 100, 110, -5, -15, 100, 110, -10, -20, 100, 110, -15, -25,
  100, 110, -20, -30, 100, 110, -25, -35, 100, 110, -30, -40, 100, 110, -35,
  -45, 100, 110, 45, 35, 105, 115, 40, 30, 105, 115, 35, 25, 105, 115, 30, 20,
  105, 115, 25, 15, 105, 115, 20, 10, 105, 115, 15, 5, 105, 115, 10, 0, 105,
  115, 5, -5, 105, 115, 0, -10, 105, 115, -5, -15, 105, 115, -10, -20, 105, 115,
  -15, -25, 105, 115, -20, -30, 105, 115, -25, -35, 105, 115, -30, -40, 105,
  115, -35, -45, 105, 115, 45, 35, 110, 120, 40, 30, 110, 120, 35, 25, 110, 120,
  30, 20, 110, 120, 25, 15, 110, 120, 20, 10, 110, 120, 15, 5, 110, 120, 10, 0,
  110, 120, 5, -5, 110, 120, 0, -10, 110, 120, -20, -30, 110, 120, -25, -35,
  110, 120, -30, -40, 110, 120, -35, -45, 110, 120, 45, 35, 115, 125, 40, 30,
  115, 125, 35, 25, 115, 125, 30, 20, 115, 125, 25, 15, 115, 125, 20, 10, 115,
  125, 15, 5, 115, 125, 10, 0, 115, 125, 5, -5, 115, 125, 0, -10, 115, 125, -5,
  -15, 115, 125, -10, -20, 115, 125, -15, -25, 115, 125, -20, -30, 115, 125,
  -25, -35, 115, 125, -30, -40, 115, 125, -35, -45, 115, 125, 45, 35, 120, 130,
  40, 30, 120, 130, 35, 25, 120, 130, 30, 20, 120, 130, 25, 15, 120, 130, 20,
  10, 120, 130, 15, 5, 120, 130, 10, 0, 120, 130, 5, -5, 120, 130, 0, -10, 120,
  130, -5, -15, 120, 130, -10, -20, 120, 130, -15, -25, 120, 130, -20, -30, 120,
  130, -25, -35, 120, 130, -30, -40, 120, 130, -35, -45, 120, 130, 45, 35, 125,
  135, 40, 30, 125, 135, 35, 25, 125, 135, 30, 20, 125, 135, 25, 15, 125, 135,
  20, 10, 125, 135, 15, 5, 125, 135, 10, 0, 125, 135, 5, -5, 125, 135, 0, -10,
  125, 135, -5, -15, 125, 135, -10, -20, 125, 135, -15, -25, 125, 135, -20, -30,
  125, 135, -25, -35, 125, 135, -30, -40, 125, 135, -35, -45, 125, 135, 45, 35,
  130, 140, 40, 30, 130, 140, 35, 25, 130, 140, 30, 20, 130, 140, 25, 15, 130,
  140, 20, 10, 130, 140, 15, 5, 130, 140, 10, 0, 130, 140, 5, -5, 130, 140, 0,
  -10, 130, 140, -5, -15, 130, 140, -10, -20, 130, 140, -15, -25, 130, 140, -20,
  -30, 130, 140, -25, -35, 130, 140, -30, -40, 130, 140, -35, -45, 130, 140, 45,
  35, 135, 145, 40, 30, 135, 145, 35, 25, 135, 145, 30, 20, 135, 145, 25, 15,
  135, 145, 20, 10, 135, 145, 15, 5, 135, 145, 10, 0, 135, 145, 5, -5, 135, 145,
  0, -10, 135, 145, -5, -15, 135, 145, -10, -20, 135, 145, -15, -25, 135, 145,
  -20, -30, 135, 145, -25, -35, 135, 145, -30, -40, 135, 145, -35, -45, 135,
  145, 45, 35, 140, 150, 40, 30, 140, 150, 35, 25, 140, 150, 30, 20, 140, 150,
  25, 15, 140, 150, 20, 10, 140, 150, 15, 5, 140, 150, 10, 0, 140, 150, 5, -5,
  140, 150, 0, -10, 140, 150, -5, -15, 140, 150, -10, -20, 140, 150, -15, -25,
  140, 150, -20, -30, 140, 150, -25, -35, 140, 150, -30, -40, 140, 150, -35,
  -45, 140, 150, 45, 35, 145, 155, 40, 30, 145, 155, 35, 25, 145, 155, 30, 20,
  145, 155, 25, 15, 145, 155, 20, 10, 145, 155, 15, 5, 145, 155, 10, 0, 145,
  155, 5, -5, 145, 155, 0, -10, 145, 155, -5, -15, 145, 155, -10, -20, 145, 155,
  -15, -25, 145, 155, -20, -30, 145, 155, -25, -35, 145, 155, -30, -40, 145,
  155, -35, -45, 145, 155, 45, 35, 150, 160, 40, 30, 150, 160, 35, 25, 150, 160,
  30, 20, 150, 160, 25, 15, 150, 160, 20, 10, 150, 160, 15, 5, 150, 160, 10, 0,
  150, 160, 5, -5, 150, 160, 0, -10, 150, 160, -5, -15, 150, 160, -10, -20, 150,
  160, -15, -25, 150, 160, -20, -30, 150, 160, -25, -35, 150, 160, -30, -40,
  150, 160, -35, -45, 150, 160,
];
export default degree;
