import React, { useEffect, useState } from "react";
import {
 Select,
 MenuItem,
 FormControl,
 InputLabel,
 CircularProgress,
 Button,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import "./styles/_index.scss";
import axios from "axios";
const Charts: React.FC = () => {
 const [files, setFiles] = useState<{
   gifFiles: { title: string; data: string }[];
 }>({ gifFiles: [] });
 const [regions, setRegions] = useState<string[]>([]);
 const [regionData, setRegionData] = useState<any[]>([]);
 const [selectedRegion, setSelectedRegion] = useState("");
 const [currentPage, setCurrentPage] = useState(0);
 const [videoUrl, setVideoUrl] = useState<string | null>(null);
 const [latitude, setLatitude] = useState<number | null>(null);
 const [longitude, setLongitude] = useState<number | null>(null);
 const [selectedType, setSelectedType] = useState("Wind");
 const [loadingLocation, setLoadingLocation] = useState(true);
 const [loadingDropdown, setLoadingDropdown] = useState(true);
 const [loadingFiles, setLoadingFiles] = useState(false);
 const loading = loadingLocation || loadingDropdown || loadingFiles;
 const itemsPerPage = 1;
 useEffect(() => {
   const fetchRegionData = async () => {
     try {
       console.log(
         `Fetching region data from: ${process.env.REACT_APP_BACKEND_IP}/api/api/api/region-data`
       );
       const response = await fetch(
         `${process.env.REACT_APP_BACKEND_IP}/api/api/api/region-data`
       );
       if (!response.ok) {
         throw new Error(`HTTP error! Status: ${response.status}`);
       }
       const data = await response.json();
       console.log("Region data received:", data);
       if (data && Array.isArray(data.regions)) {
         setRegionData(data.regions);
         console.log(`Loaded ${data.regions.length} region data entries`);
       }
     } catch (error) {
       console.error("Error fetching region data:", error);
     }
   };
   fetchRegionData();
 }, []);
 useEffect(() => {
  const fetchDropdownNames = async () => {
    setLoadingDropdown(true);
    try {
      console.log(
        `Fetching dropdown data from: ${process.env.REACT_APP_BACKEND_IP}/api/api/api/dropdown-list`
      );
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/api/api/api/dropdown-list`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Dropdown data received:", data);
      if (data && Array.isArray(data.drop_down_list_names)) {
        setRegions(data.drop_down_list_names);
        console.log(`Loaded ${data.drop_down_list_names.length} regions`);
      } else {
        console.error("Invalid dropdown data format:", data);
      }
    } catch (error) {
      console.error("Error fetching dropdown names:", error);
    } finally {
      setLoadingDropdown(false);
    }
  };
  fetchDropdownNames();
}, []);
 useEffect(() => {
   const fetchLocationData = async () => {
     setLoadingLocation(true);
     try {
       const response = await axios.get(
         `${process.env.REACT_APP_BACKEND_IP
         }/api/cyclone/${localStorage.getItem("fid")}/`
       );
       const data = response.data;
       if (
         data.project_location?.latitude &&
         data.project_location?.longitude
       ) {
         const lat = data.project_location.latitude;
         const lon = data.project_location.longitude;
         setLatitude(lat);
         setLongitude(lon);
         findNearestRegion(lat, lon);
       } else {
         console.log("No location data found in the response");
         setLoadingLocation(false);
       }
     } catch (error) {
       console.error("Error fetching location data:", error);
       setLoadingLocation(false);
     }
   };
   const findNearestRegion = async (lat: number, lon: number) => {
     try {
       console.log(`Finding nearest region for: lat=${lat}, lon=${lon}`);
       const response = await fetch(
         `${process.env.REACT_APP_BACKEND_IP}/api/api/api/nearest-region?latitude=${lat}&longitude=${lon}`
       );
       if (!response.ok) {
         throw new Error(`HTTP error! Status: ${response.status}`);
       }
       const data = await response.json();
       console.log("Nearest region data:", data);
       if (data.nearest_region) {
         setSelectedRegion(data.nearest_region.name);
         console.log(
           `Automatically selected region: ${data.nearest_region.name}`
         );
       }
     } catch (error) {
       console.error("Error finding nearest region:", error);
     } finally {
       setLoadingLocation(false);
     }
   };
   fetchLocationData();
 }, []);
 useEffect(() => {
   if (!selectedType || !selectedRegion) return;
   const fetchFiles = async () => {
     setLoadingFiles(true);
     try {
       const response = await fetch(
         `${process.env.REACT_APP_BACKEND_IP
         }/api/api/api/images/?chart_type=${selectedType.toLowerCase()}&region=${encodeURIComponent(
           selectedRegion
         )}`
       );
       const data = await response.json();
       setFiles({ gifFiles: data.images || [] });
     } catch (error) {
       console.error("Error fetching files:", error);
     } finally {
       setLoadingFiles(false);
     }
   };
   fetchFiles();
 }, [selectedType, selectedRegion]);
 useEffect(() => {
   if (videoUrl && videoUrl.startsWith('blob:')) {
     URL.revokeObjectURL(videoUrl);
   }
   setVideoUrl(null);
   setCurrentPage(0);
 }, [selectedType, selectedRegion]);
 const customRound = (value: number) => Math.round(value);
 const getNearestLatLon = (lat: number, lon: number) => {
   const nearestLat = Math.round(Math.abs(lat) / 5) * 5;
   const nearestLon = Math.round(lon / 5) * 5;
   const diffLat = Math.abs(lat) - nearestLat;
   const diffLon = lon - nearestLon;
   const claty =
     lat > 0
       ? customRound((5 - diffLat) * 55.6 + 22)
       : customRound((5 + diffLat) * 55.6 + 22);
   const clatx = customRound((5 + diffLon) * 55.6 + 22);
   return { nearestLat, nearestLon, clatx, claty };
 };
 const handleCloseVideo = () => {
   setVideoUrl(null);
 };
 const handleAnimate = () => {
   if (files.gifFiles.length === 0) {
     alert("No images available for the selected type and region.");
     return;
   }
   setLoadingFiles(true);
   const videoUrl = `${process.env.REACT_APP_BACKEND_IP}/api/api/api/video/?chart_type=${selectedType.toLowerCase()}&region=${encodeURIComponent(selectedRegion)}&timestamp=${new Date().getTime()}`;
   fetch(videoUrl)
     .then(response => {
       if (!response.ok) {
         const errorMsg = `Server returned ${response.status}: ${response.statusText}`;
         console.error(errorMsg);
         throw new Error(errorMsg);
       }
       const contentType = response.headers.get('content-type');
       if (!contentType || !contentType.includes('video/')) {
         console.error('Invalid content type:', contentType);
         throw new Error('Server did not return a valid video file');
       }
       return response.blob();
     })
     .then(blob => {
       const videoObjectUrl = URL.createObjectURL(blob);
       console.log("Video loaded successfully, size:", blob.size);
       setVideoUrl(videoObjectUrl);
     })
     .catch(error => {
       console.error("Error fetching video:", error);
       alert(`Failed to load video: ${error.message}. Please try again later.`);
     })
     .finally(() => {
       setLoadingFiles(false);
     });
 };
 const handleNext = () => {
  if ((currentPage + 1) * itemsPerPage < files.gifFiles.length) {
    setCurrentPage((prev) => prev + 1);
  }
};
const handlePrev = () => {
  if (currentPage > 0) {
    setCurrentPage((prev) => prev - 1);
  }
};
const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
  setSelectedType(e.target.value as string);
};
const handleRegionChange = (e: React.ChangeEvent<{ value: unknown }>) => {
  setSelectedRegion(e.target.value as string);
};
const paginatedImages = files.gifFiles.slice(
  currentPage * itemsPerPage,
  currentPage * itemsPerPage + itemsPerPage
);
return (
<div className="chart">
    {loading ? (
<div className="loader">{/* <CircularProgress /> */}</div>
    ) : (
<>
<div className="header">
<h1>Wind - Waves - Current Charts</h1>
</div>
<div className="controls">
<FormControl className="dropdown">
<InputLabel>Select Chart Type</InputLabel>
<Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
>
              {["Wind", "Waves", "Current"].map((type) => (
<MenuItem key={type} value={type}>
                  {type}
</MenuItem>
              ))}
</Select>
</FormControl>
<FormControl className="dropdown" fullWidth>
<InputLabel id="region-select-label">Select Region</InputLabel>
<Select
              labelId="region-select-label"
              id="region-select"
              value={selectedRegion}
              label="Select Region"
              onChange={(e) => {
                setSelectedRegion(e.target.value);
                setCurrentPage(0);
                setVideoUrl(null);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
>
              {regions.length > 0 ? (
                regions.map((region, index) => (
<MenuItem key={index} value={region}>
                    {region}
</MenuItem>
                ))
              ) : (
<MenuItem disabled value="">
<em>No regions available</em>
</MenuItem>
              )}
</Select>
</FormControl>
</div>
<div className="image-card">
          {videoUrl ? (
<div className="video-container">
<button className="close-icon" onClick={() => {
                if (videoUrl && videoUrl.startsWith('blob:')) {
                  URL.revokeObjectURL(videoUrl);
                }
                setVideoUrl(null);
              }}>
                ✖
</button>
<video
                controls
                autoPlay
                className="chart-video"
                playsInline
                onError={(e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
                  const videoElement = e.currentTarget;
                  console.error("Video playback error:", videoElement.error);
                  alert(`Error playing video: ${videoElement.error?.message || 'Unknown error'}. Please try again.`);
                  if (videoUrl.startsWith('blob:')) {
                    URL.revokeObjectURL(videoUrl);
                  }
                  setVideoUrl(null);
                }}
>
<source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
</video>
</div>
          ) : (
<>
              {paginatedImages.length > 0 ? (
                paginatedImages.map((file, index) => (
<div key={index} className="image-wrapper">
<ArrowCircleLeftIcon
                      className="nav-icon left"
                      onClick={handlePrev}
                      style={{
                        visibility: currentPage === 0 ? "hidden" : "visible",
                      }}
                    />
<img
                      src={`data:image/png;base64,${file.data}`}
                      alt={file.title}
                      className="chart-image"
                    />
<ArrowCircleRightIcon
                      className="nav-icon right"
                      onClick={handleNext}
                      style={{
                        visibility:
                          (currentPage + 1) * itemsPerPage >=
                            files.gifFiles.length
                            ? "hidden"
                            : "visible",
                      }}
                    />
<p>{file.title}</p>
</div>
                ))
              ) : (
<p></p>
              )}
</>
          )}
</div>
<div className="animate-btn">
<Button
            variant="contained"
            color="primary"
            onClick={handleAnimate}
            disabled={files.gifFiles.length === 0}
>
            Animate
</Button>
</div>
</>
    )}
</div>
);
};
export default Charts;
