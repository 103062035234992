import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import squall from "./../../assets/squall.png";
import Grid from "@mui/material/Unstable_Grid2";
import typhoon from "./../../assets/typhoon.png";
import forecast from "./../../assets/forecast.png";
import lighting from "./../../assets/lightning.png";
import quickOverview from "./../../assets/quick-overview.png";
import weatherWindow from "./../../assets/weather-window.png";
import satellite from "./../../assets/satpic.png";
import chart from "./../../assets/chart.png";
import ensemble from "./../../assets/ensemble.png";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import "./style.scss";
import axios from "axios";

interface CardData {
name: string;
url: string;
image: string;
content: React.ReactNode;
color?: string;
}
interface TrackData {
  storm_track_id: number;
  storm_name: string;
  storm_description: string | null;
  status_id: number;
  created_by: number;
  created_on: string;
  updated_by: number | null;
  updated_on: number | null;
  last_accessed_on: string;
 }
const CardPlan: React.FC = () => {
const [data, setData] = useState<CardData[]>([]);
const [stormData, setStormData] = useState<any[]>([]);
//const [loading, setLoading] = useState(true);
const [error, setError] = useState<string | null>(null);
const [trackData, setTrackData] = useState<TrackData | null>(null);
const [hasMapHovers, setHasMapHovers] = useState(false);
const [loadedDatas, setLoadedDatas] = useState(false);
const [mapHovers, setMapHovers] = useState<{ [key: string]: any }>({});

const [jsonData, setJsonData] = useState<any>();
const [title, setTitle] = useState("");
const [color, setColor] = useState('green');

const [pdfStatus, setPdfStatus] = useState<PdfStatus>({ Typhoon: false });
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const checkPdfAvailability = async () => {
      try {
        console.log("Checking PDF availability for card view");
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_BACKEND_IP}api/checkpdfthypoon/`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          timeout: 3000,
        });
        console.log(`Card PDF check response: ${response.status}`);
        // Update PDF status based on API response
        setPdfStatus((prevStatus) => ({
          ...prevStatus,
          Typhoon: response.status === 200, // true if PDF found (200 status)
        }));
      } catch (error) {
        console.log("PDF check error in card view:", error);
        setPdfStatus((prevStatus) => ({
          ...prevStatus,
          Typhoon: false, // No PDF available
        }));
      }
    };
    // Check immediately on component mount
    checkPdfAvailability();
    // Set up interval to check every 10 seconds
    const intervalId = setInterval(checkPdfAvailability, 5000);
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

 useEffect(() => {
   fetchStormData();
 }, []);
 useEffect(() => {
   if (trackData) {
     fetchLastEditedData();
   }
 }, [trackData]);

useEffect(() => {
    if (!loadedDatas) {
      fetch(`${process.env.REACT_APP_BACKEND_IP}/api/stormdatas/`)
        .then((response) => response.json())
        .then((data) => {
          if (data.track_datas) {
            setStormData(data.track_datas);
          }
          if (data.map_hovers) {
            setMapHovers(data.map_hovers);
            setHasMapHovers(data.map_hovers.length > 0);
          } else {
            setHasMapHovers(false);
          }
          setLoadedDatas(true);
        })
        .catch(() => setLoadedDatas(true));
    }
  }, [loadedDatas]);

/*const fetchStormData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_IP}/api/stormdatas`
      );
      const apiData = response.data;
      if (apiData.storm_datas.length === 0) {
        setStormData({
          name: "No storm data available",
          description: "Please check back later.",
        });
        console.log("No storm data available.");
      } else {
        setStormData(apiData.storm_datas[0]);
        console.log("Storm data set:", apiData.storm_datas[0]);
      }
      if (apiData.track_datas.length === 0) {
        setTrackData({
          storm_track_id: 0,
          storm_name: "No track data",
          storm_description: null,
          status_id: -1,
          created_by: 0,
          created_on: "N/A",
          updated_by: null,
          updated_on: null,
          last_accessed_on: "N/A",
        });
        console.log("No track data available.");
      } else {
        const track = apiData.track_datas[0];
        setTrackData(track);
        console.log("Track data set:", track);
      }
    } catch (error) {
      console.error("Error fetching storm data:", error);
    }
   };*/

const fetchStormData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_IP}/api/stormdatas`
      );
      const apiData = response.data;
      if (apiData.storm_datas.length === 0) {
        setStormData({
          name: "No storm data available",
          description: "Please check back later.",
        });
        console.log("No storm data available.");
      } else {
        setStormData(apiData.storm_datas[0]);
        console.log("Storm data set:", apiData.storm_datas[0]);
      }
      if (apiData.track_datas.length === 0) {
        setTrackData({
          storm_track_id: 0,
          storm_name: "No track data",
          storm_description: null,
          status_id: 0,
          created_by: 0,
          created_on: "N/A",
          updated_by: null,
          updated_on: null,
          last_accessed_on: "N/A",
        });
        console.log("No track data available.");
      } else {
        const track = apiData.track_datas[0];
        setTrackData(track);
        console.log("Track data set:", track);
      }
    } catch (error) {
      console.error("Error fetching storm data:", error);
    }
  };

const returnTimeDifference = (date: string) => {
   const now = new Date();
   const pastDate = new Date(date);
   const timeDiff = Math.abs(now.getTime() - pastDate.getTime());
   const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
   return diffDays + " days";
};

useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_IP}/api/getcsv/${localStorage.fid}`
        );
        if (response.status === 404 || !response.data || response.data.length === 0) {
          setTitle("There are currently no severe warnings active for this location");
          setColor("green");
        } else {
          const csvText = response.data;
          setJsonData(csvText);
          setTitle(csvText[0]["Squall Header"]);
          setColor("red");
        }
      } catch (error: any) {
        setTitle("Error fetching data");
        setColor("green");
      }
    };
    fetchData();
  }, []);

const fetchLastEditedData = async () => {
    const fid = localStorage.getItem("fid");
    const typhoonColor = hasMapHovers ? "animate-blink-red" : "green";
    if (fid) {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BACKEND_IP
          }/api/lastedited/${localStorage.getItem("fid")}`
        );
        const lastEditedData = response.data;
        const cardData: CardData[] = [
          {
            name: "Forecast",
            url: "forecast",
            image: forecast,
            content: lastEditedData.forecast
              ? `Updated ${returnTimeDifference(lastEditedData.forecast)} ago`
              : "",
          },
          {
            name: "Quick Overview",
            url: "overview",
            image: quickOverview,
            content: lastEditedData.forecast
              ? `Updated ${returnTimeDifference(lastEditedData.forecast)} ago`
              : "",
          },
          {
            name: "Weather Window",
            url: "weather",
            image: weatherWindow,
            content: lastEditedData.forecast
              ? `Updated ${returnTimeDifference(lastEditedData.forecast)} ago`
              : "",
          },
          {
            name: "Squall",
            url: "squall",
            image: squall,
            content: "",
            color: color,
          },
		   {
            name: "Tropical Cyclone",
            url: "typhoon",
            image: typhoon,
            content:
              trackData?.status_id === 8
                ? `Updated ${returnTimeDifference(
                    trackData.last_accessed_on
                  )} ago`
                : "",
            color: typhoonColor,
          },
          {
            name: "Satellite",
            url: "satellite",
            image: satellite,
            content: "",
            //color: "green",
          },
          {
            name: "Ensemble and probability",
            url: "dashboard",
            image: ensemble,
            content: <span style={{ fontSize: "10px" }}>under construction</span>,
           // color: "green",
          },
{
            name: "Charts",
            url: "charts",
            image: chart,
            content: "",
           // color: "green",
          },
          {
            name: "Nowcast",
            url: "dashboard",
            image: squall,
            content: <span style={{ fontSize: "10px" }}>under construction</span>,
           // color: "green",
          },
 {
            name: "Navigator",
            url: "dashboard",
            image: squall,
            content: <span style={{ fontSize: "10px" }}>under construction</span>,
           // color: "green",
          },
{
            name: "Archives",
            url: "dashboard",
            image: squall,
            content: <span style={{ fontSize: "10px" }}>under construction</span>,
           // color: "green",
          },
{
            name: "Glossary",
            url: "dashboard",
            image: squall,
            content: <span style={{ fontSize: "10px" }}>under construction</span>,
           // color: "green",
          },
        ];
        setData(cardData);
      } catch (error) {
        console.error("Error fetching last edited data:", error);
      }
    } else {
      const defaultCardData: CardData[] = [
        {
          name: "Forecast",
          url: "forecast",
          image: forecast,
          content: "No data available",
        },
        {
          name: "Quick Overview",
          url: "overview",
          image: quickOverview,
          content: "No data available",
        },
        {
          name: "Weather Window",
          url: "weather",
          image: weatherWindow,
          content: "No data available",
        },
		{
          name: "Squall",
          url: "squall",
          image: squall,
          content: "",
          color: "green",
        },
        {
          name: "Typhoon",
          url: "typhoon",
          image: typhoon,
          content: "No data available",
          color: "green",
        },
        {
          name: "Lightning",
          url: "lightning",
          image: lightning,
          content: "",
          color: "green",
        },
      ];
      setData(defaultCardData);
      console.log("Default card data set:", defaultCardData);
    }
  };
               
  return (
    <>
      {data.map((item: CardData) => (
        <Grid key={item.name} xs={12} sm={6} md={6} lg={4}>
          <Link to={`/${item.url}`} state={{ title: item.name }}>
            <div className={"hoverable"}>
              <CardMedia
                className="card-img"
                sx={{ height: 100 }}
                image={item.image}
                title={item.name}
                style={{ borderRadius: "10px" }}
              />
              <Card className="parent_card" style={{ borderRadius: "10px" }}>
                <CardContent
                  className="child_card"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: "0.9rem" }}>
 {item.name}
</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.content}
                    </Typography>
                  </div>
                  {item.color && (
                    <span
                      className={`color_icon ${
                        item.color === "red"
                          ? "color_icon_red blinking-circle"
                          : `color_icon_${item.color}`
                      }`}
                    ></span>
                  )}
                </CardContent>
              </Card>
            </div>
          </Link>
        </Grid>
      ))}
    </>
  );
};

export default CardPlan;
