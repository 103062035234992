import store from "../../store";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useState, useRef, useEffect } from "react";
import WeatherLoader from "../../components/loader";
import "./styles/_index.scss";
import axios from "axios";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

type CriteriaDetail = {
  forecast_osf_criteria_id: number;
  field_id: number;
  field_name: string;
  margin_value: string;
  value: string;
  comparison_operator_id: number;
};
type DataRecord = Record<string, number | null | undefined>;

export default function Weather() {
  // Reference value for current Browser Window Width
  const windowWidth = useRef(window.innerWidth);

  // Sets Track about the SideNav Bar Open/Close State
  const [open, setOpen] = useState(windowWidth.current > 1000 ? true : false);
  const data = useSelector((state: any) => state?.app);

  const [criteriaDatas, setCriteriaDatas] = useState<any>([]);
  const [criteriaDetailDatas, setCriteriaDetailDatas] = useState<any>([]);
  const [TableDatas, setTableDatas] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [SelectValue, setSelectValue] = useState<any>("");

  useEffect(() => {
    store.dispatch({
      type: "TOGGLE_MENU",
      payload: windowWidth.current > 1000 ? true : false,
    });
  }, []);

  useEffect(() => {
    setOpen(data.toggle);
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_IP}/api/weather/`,
          { forecast_id: localStorage.getItem("fid") },
          {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: "Basic " + btoa("admin:admin"),
            },
          }
        );
        const data = response.data;
        setCriteriaDatas(data.criteria_datas || []);
        setCriteriaDetailDatas(data.criteria_detail_datas || []);
        setTableDatas(data.datas || []);
        setSelectValue(
          String(data.criteria_detail_datas[0]?.forecast_osf_criteria_id || "")
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching table data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  function dateFormat(date: Date) {
    var montharray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.getDate();
    var m = montharray[date.getMonth()];
    return d + " " + m;
  }

  function getColor(
    criteriaDetailDatas: CriteriaDetail[],
    data: DataRecord,
    SelectValue: string
  ): string {
    const selectedCriteria = criteriaDetailDatas.filter(
      (c_data) => c_data.forecast_osf_criteria_id === parseInt(SelectValue, 10)
    );
    if (selectedCriteria.length === 0) {
      return "white";
    }
    const colorResults = selectedCriteria.map((c_data) => {
      const fieldValue = data[c_data.field_name];
      return getColorForField(
        fieldValue,
        parseFloat(c_data.margin_value),
        parseFloat(c_data.value),
        c_data.comparison_operator_id
      );
    });
    if (colorResults.includes("red_overview")) {
      return "red_overview";
    } else if (colorResults.every((color) => color === "green_overview")) {
      return "green_overview";
    } else if (
      colorResults.includes("yellow_overview") &&
      colorResults.includes("green_overview")
    ) {
      return "yellow_overview";
    } else if (colorResults.every((color) => color === "yellow_overview")) {
      return "yellow_overview";
    }
    return "white";
  }
  function getColorForField(
    data: number | undefined | null,
    marginValue: number,
    value: number,
    comparisonOperatorId: number
  ): string {
    if (data === undefined || data === null) {
      return "white";
    }
    switch (comparisonOperatorId) {
      case 1:
        return data < value
          ? "red_overview"
          : data < marginValue
          ? "yellow_overview"
          : "green_overview";
      case 2:
        return data > value
          ? "red_overview"
          : data > marginValue
          ? "yellow_overview"
          : "green_overview";
      case 3:
        return data <= value
          ? data <= marginValue
            ? "green_overview"
            : "yellow_overview"
          : "red_overview";
      case 4:
        return data >= value
          ? data >= marginValue
            ? "green_overview"
            : "yellow_overview"
          : "red_overview";
      case 5:
        return data === value ? "green_overview" : "red_overview";
      case 6:
        return data !== value ? "green_overview" : "red_overview";
      case 7:
        return data > marginValue && data < value
          ? "green_overview"
          : "red_overview";
      case 8:
        return data === null ? "green_overview" : "red_overview";
      case 9:
        return data !== null ? "green_overview" : "red_overview";
      default:
        return "white";
    }
  }

  return (
    <div className={open ? "sideNavOpen" : "sideNavClose"}>
      <Box className="fug-container bg-default flex sidenav-full">
        <div className="content-wrap dashboard">
          {!loading ? (
            criteriaDatas?.length > 0 ? (
              <Main
                open={open}
                className={"main"}
                style={{
                  overflow: "auto",
                  display: "flex",
                  paddingBlock: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "35px",
                }}
              >
                <div className={"heading_div"}></div>

                <div className={"maincontainer"}>
                  {TableDatas.map((datas: any) => (
                    <div
                      key={datas[0].datetimeutc}
                      className={"mini_header_box"}
                    >
                      <div className={"mini_header_main"}>
                        <span className={"mini_header_date"}>
                          {dateFormat(new Date(datas[0].datetimeutc))}
                        </span>
                      </div>
                      <div className={"mini_header_time"}>
                        {datas.map((data: any) => (
                          <span
                            key={data.datetimeutc}
                            className={"mini_header_time"}
                          >
                            {new Date(data.datetimeutc).getHours() === 0
                              ? "0"
                              : new Date(data.datetimeutc).getHours()}
                          </span>
                        ))}
                      </div>
                      {criteriaDatas?.map((rows: any) => (
                        <div key={rows.criteria_name}>
                          <span style={{ textAlign: "center" }}>
                            {rows.criteria_name}
                          </span>
                          <div className={"mini_color_box"}>
                            {datas.map((data: any, index: number) => (
                              <span
                                key={`${data.datetimeutc}-${index}`}
                                style={{ width: "100%" }}
                                className={getColor(
                                  criteriaDetailDatas,
                                  data,
                                  rows.forecast_osf_criteria_id.toString()
                                )}
                              ></span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                <div className={"legend_div"}>
                  <span className={"legend_heading"}>Status</span>
                  <div className={"lengend_details_div"}>
                    <div className={"legend_inner_div"}>
                      <span
                        style={{ width: "2.5em" }}
                        className={["high_legend"].join(" ")}
                      ></span>
                      <span className={"legend_content"}>Above the limit</span>
                    </div>
                    <div className={"legend_inner_div"}>
                      <span
                        style={{ width: "2.5em" }}
                        className={["normal_legend"].join(" ")}
                      ></span>
                      <span className={"legend_content"}>Marginal</span>
                    </div>
                    <div className={"legend_inner_div"}>
                      <span
                        style={{ width: "2.5em" }}
                        className={["low_legend"].join(" ")}
                      ></span>
                      <span className={"legend_content"}>Below the limit</span>
                    </div>
                  </div>
                </div>
              </Main>
            ) : loading === false ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "90vh",
                }}
              >
                <p
                  style={{
                    fontSize: "28px",
                    color: "black",
                    backgroundColor: "grey",
                    padding: 10,
                    borderRadius: 6,
                  }}
                >
                  No Data Found
                </p>
              </div>
            ) : null
          ) : (
            <div
              style={{
                width: "100%",
                height: "90vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <WeatherLoader />
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}
