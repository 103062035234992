import React, { useState, useEffect, useCallback, useRef } from "react";
import "./styles/_index.scss";

interface GeographicCoordinates {
  lat: string;
  lng: string;
}

interface SectionConfig {
  id: string;
  label: string;
}

const Satellite: React.FC = () => {
  const SECTIONS: SectionConfig[] = [
    { id: "IR", label: "IR Section" },
    { id: "V", label: "V Section" }
  ];
  const [section, setSection] = useState<string>(SECTIONS[0].id);
  const [imageIndex, setImageIndex] = useState<number>(1);
  const [currentImageId, setCurrentImageId] = useState<string>("");
  const [imageSrc, setImageSrc] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<string>("");
  const [availableImages, setAvailableImages] = useState<string[]>([]);
  const [userGeoCords, setUserGeoCords] = useState<GeographicCoordinates>({ 
    lat: "", 
    lng: "" 
  });
  const isInitialLoadRef = useRef(true);
  const currentSectionRef = useRef(section);
  const availableImagesRef = useRef(availableImages);
  const currentImageIdRef = useRef(currentImageId);
  const customRound = (value: number): number => {
    return Math.round(value);
  };
  const getNearestLatLon = (lat: number, lon: number) => {
    const nearestLat = Math.round(Math.abs(lat) / 5) * 5;
    const nearestLon = Math.round(lon / 5) * 5;
    console.log("nearestLat nearestLon===========>", nearestLat, nearestLon);
    
    const diffLat = Math.abs(lat) - nearestLat;
    const diffLon = lon - nearestLon;
    
    const claty = lat > 0
      ? customRound((5 - diffLat) * 55.6 + 22)
      : customRound((5 + diffLat) * 55.6 + 22);
    
    const clatx = customRound((5 + diffLon) * 55.6 + 22);
    
    return { nearestLat, nearestLon, clatx, claty };
  };


  const extractImageIdFromUrl = (url: string): string | null => {
   
    try {
      if (url.includes('satpic-')) {
        const match = url.match(/satpic-[\w-]*\d{4}/);
        return match ? match[0] : null;
      }
      return null;
    } catch (e) {
      console.error("Error extracting image ID from URL:", e);
      return null;
    }
  };


  const fetchAvailableImages = useCallback(async (currentSection: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP || ""}/api/api/available_images/${currentSection}/`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch images for ${currentSection}`);
      }

      const imagesData = await response.json();
      const images = imagesData.images || [];

      console.log(`Available images for ${currentSection}:`, images);

      // Update available images state and ref
      setAvailableImages(images);
      availableImagesRef.current = images;

      return images;
    } catch (error) {
      console.error(`Error fetching available images for ${currentSection}:`, error);
      setErrorDetails(`Failed to load images: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setAvailableImages([]);
      availableImagesRef.current = [];
      return [];
    }
  }, []);

  const fetchImageByCoordinates = useCallback(async (
    currentSection: string,
    coords: GeographicCoordinates
  ) => {
    setIsLoading(true);
    setLoadError(false);
    setErrorDetails("");

    try {
      if (!coords.lat || !coords.lng) {
        throw new Error("Missing coordinates for image fetch");
      }
      
      // Calculate nearest lat/lon positions
      const lat = parseFloat(coords.lat);
      const lon = parseFloat(coords.lng);
      
      const { nearestLat, nearestLon, clatx, claty } = getNearestLatLon(lat, lon);
      
      console.log(
        `Fetching initial image by coordinates: section=${currentSection}, original coords=${coords.lat},${coords.lng}`
      );
      console.log(`Calculated nearest position: lat=${nearestLat}, lon=${nearestLon}, x=${clatx}, y=${claty}`);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP || ""}/api/api/display_image/${currentSection}/static-0001/?lat=${coords.lat}&lon=${coords.lng}&mode=api`
      );
      
      if (!response.ok) {
        throw new Error(`Failed to fetch image by coordinates: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (data && data.image_url) {
        setImageSrc(data.image_url);
        
        const imageNameFromUrl = data.matched_image_id || 
                              extractImageIdFromUrl(data.image_url) || 
                              availableImagesRef.current[0];
        
        if (!imageNameFromUrl) {
          throw new Error("Could not determine image ID");
        }
        
        console.log("Matched image ID:", imageNameFromUrl);
        setCurrentImageId(imageNameFromUrl);
        currentImageIdRef.current = imageNameFromUrl;
        
        const foundIndex = availableImagesRef.current.findIndex(
          img => img === imageNameFromUrl
        );
        
        if (foundIndex >= 0) {
          setImageIndex(foundIndex + 1);
          console.log(`Image index set to ${foundIndex + 1} of ${availableImagesRef.current.length}`);
        } else {
          setImageIndex(1);
          console.log(`Image not found in available images, defaulting to index 1`);
        }
      } else {
        throw new Error("Invalid image data received");
      }
    } catch (error) {
      console.error("Error fetching image by coordinates:", error);
      setImageSrc("");
      setLoadError(true);
      setErrorDetails(error instanceof Error ? error.message : "Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch specific image by ID
  const fetchImageById = useCallback(async (
    currentSection: string, 
    imageId: string
  ) => {
    setIsLoading(true);
    setLoadError(false);
    setErrorDetails("");

    try {
      if (!userGeoCords.lat || !userGeoCords.lng) {
        throw new Error("Missing coordinates from backend");
      }
      
      console.log(
        `Fetching specific image: section=${currentSection}, imageId=${imageId}, coords=${userGeoCords.lat},${userGeoCords.lng}`
      );

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP || ""}/api/api/display_image/${currentSection}/${imageId}/?lat=${userGeoCords.lat}&lon=${userGeoCords.lng}&mode=api`
      );
      
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (data && data.image_url) {
        setImageSrc(data.image_url);
        setCurrentImageId(imageId);
        currentImageIdRef.current = imageId;
      } else {
        throw new Error("Invalid image data received");
      }
    } catch (error) {
      console.error("Error fetching image by ID:", error);
      setImageSrc("");
      setLoadError(true);
      setErrorDetails(error instanceof Error ? error.message : "Unknown error");
    } finally {
      setIsLoading(false);
    }
  }, [userGeoCords]);

  // Fetch coordinates and initial image
  const fetchInitialData = useCallback(async () => {
    try {
      // Get project ID from local storage
      const fid = localStorage.getItem("fid") || "default";
      
      console.log(`Fetching project location for ID: ${fid}`);
      
      // Fetch project location from backend
      const locationResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_IP || ""}/api/cyclone/${fid}/`
      );

      if (!locationResponse.ok) {
        throw new Error(`Failed to fetch project location: ${locationResponse.status}`);
      }

      const locationData = await locationResponse.json();
      const { latitude, longitude } = locationData.project_location || {};

      // Update coordinates from backend
      if (latitude && longitude) {
        const coords = {
          lat: latitude.toString(),
          lng: longitude.toString()
        };
        console.log("Setting coordinates from backend:", coords);
        setUserGeoCords(coords);
        
        await fetchAvailableImages(section);
        await fetchImageByCoordinates(section, coords);
      } else {
        throw new Error("No coordinates received from backend");
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setErrorDetails(`Error initializing: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setIsLoading(false);
      setLoadError(true);
    }
  }, [section, fetchAvailableImages, fetchImageByCoordinates]);

  const handleSectionChange = useCallback(async (newSection: string) => {
    if (section !== newSection) {
      setSection(newSection);
      currentSectionRef.current = newSection;
      let currentIdNumber = 1;
      try {
        if (currentImageId) {
          const match = currentImageId.match(/\d+$/);
          if (match) {
            currentIdNumber = parseInt(match[0], 10);
          }
        }
      } catch (e) {
        console.error("Error extracting ID number:", e);
        currentIdNumber = 1;
      }

      const newImages = await fetchAvailableImages(newSection);
      
      let newImageId: string;
      if (newSection === 'IR') {
        newImageId = `satpic-${currentIdNumber.toString().padStart(4, '0')}`;
      } else {
        newImageId = `satpic-vis-${currentIdNumber.toString().padStart(4, '0')}`;
      }
      
      console.log(`Attempting to find matching image in new section: ${newImageId}`);
      
      if (newImages.includes(newImageId)) {
        const newIndex = newImages.indexOf(newImageId) + 1;
        console.log(`Found matching image at index ${newIndex}`);
        setImageIndex(newIndex);
        await fetchImageById(newSection, newImageId);
      } else {
        console.log(`No matching image found, using default image`);
        
        if (newImages.length > 0) {
          setImageIndex(1);
          await fetchImageById(newSection, newImages[0]);
        } else {
          console.log(`No images available, fetching by coordinates`);
          await fetchImageByCoordinates(newSection, userGeoCords);
        }
      }
    }
  }, [section, currentImageId, fetchAvailableImages, fetchImageById, fetchImageByCoordinates, userGeoCords]);

  const handleNextImage = useCallback(() => {
    const currentImages = availableImagesRef.current;
    const currentSection = currentSectionRef.current;
    
    if (currentImages.length === 0) return;
    
    const currentIndex = imageIndex;
    const newIndex = currentIndex >= currentImages.length
      ? 1
      : currentIndex + 1;
    
    console.log(`Navigating to next image: index ${currentIndex} -> ${newIndex}`);
    setImageIndex(newIndex);
    
    const newImageId = currentImages[newIndex - 1];
    console.log(`New image ID: ${newImageId}`);
    fetchImageById(currentSection, newImageId);
  }, [imageIndex, fetchImageById]);

  const handlePrevImage = useCallback(() => {
    const currentImages = availableImagesRef.current;
    const currentSection = currentSectionRef.current;
    
    if (currentImages.length === 0) return;
    
    const currentIndex = imageIndex;
    const newIndex = currentIndex <= 1
      ? currentImages.length
      : currentIndex - 1;
    
    console.log(`Navigating to previous image: index ${currentIndex} -> ${newIndex}`);
    setImageIndex(newIndex);
    
    const newImageId = currentImages[newIndex - 1];
    console.log(`New image ID: ${newImageId}`);
    fetchImageById(currentSection, newImageId);
  }, [imageIndex, fetchImageById]);

  useEffect(() => {
    if (isInitialLoadRef.current) {
      fetchInitialData();
      isInitialLoadRef.current = false;
    }
  }, [fetchInitialData]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        e.preventDefault();
        handleNextImage();
      }
      if (e.key === "ArrowLeft") {
        e.preventDefault();
        handlePrevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextImage, handlePrevImage]);

  return (
    <div className="outer-container">
      <div className="inner-container">
        <div className="image-frame">
          {/* Previous Image Button */}
          <button
            className="nav-btn nav-btn-prev"
            onClick={handlePrevImage}
            aria-label="Previous Image"
            disabled={isLoading}
          >
            &#10094;
          </button>

          {/* Image Display Area */}
          {isLoading ? (
            <div className="loading-indicator">
              Loading satellite image...
            </div>
          ) : loadError ? (
            <div className="error-message">
              Failed to load image. {errorDetails}
            </div>
          ) : (
            imageSrc && (
                <img
                  src={imageSrc}
                  alt={`Satellite imagery (${section} section)`}
                  className="center-image"
                  onError={() => setLoadError(true)}
                />
            )
          )}

          {/* Next Image Button */}
          <button
            className="nav-btn nav-btn-next"
            onClick={handleNextImage}
            aria-label="Next Image"
            disabled={isLoading}
          >
            &#10095;
          </button>

          {/* Section Selection Buttons */}
          <div className="section-buttons">
            {SECTIONS.map((sectionConfig) => (
              <button
                key={sectionConfig.id}
                className={`section-btn ${section === sectionConfig.id ? "active" : ""}`}
                onClick={() => handleSectionChange(sectionConfig.id)}
              >
                {sectionConfig.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Satellite;
