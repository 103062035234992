import { useState, useRef, useEffect } from "react";
import "./styles/_index.scss";
import store from "../../store";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Card, Typography, Button} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CardPlan from "../../components/dashboard/card";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import OperationsComponent from "../login/operations/operations";
//import OperationsComponent from "../../container/login/operations/operations";
import axios from "axios";

const drawerWidth = 0;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function DashBoard() {
  // Reference value for current Browser Window Width
  const windowWidth = useRef(window.innerWidth);
  const [stormData, setStormData] = useState([]);
  const [mapHovers, setMapHovers] = useState<any>([]);
  const [loadedDatas, setLoadedDatas] = useState(false);
  // Sets Track about the SideNav Bar Open/Close State
  const [open, setOpen] = useState(windowWidth.current > 1000 ? true : false);
  const data = useSelector((state: any) => state?.app);
  //const data = useSelector((state: any) => state?.app);
  const isAdmin = localStorage.getItem("type") === "admin";
  const [operationOpen, setOperationOpen] = useState(false);

  useEffect(() => {
    store.dispatch({
      type: "TOGGLE_MENU",
      payload: windowWidth.current > 1000 ? true : false,
    });
  }, []);

  useEffect(() => {
    setOpen(data.toggle);
  }, [data]);

  useEffect(() => {
    if (loadedDatas) {
      return;
    }
    fetch(`${process.env.REACT_APP_BACKEND_IP}/api/stormdatas/`)
      .then((data) => data.json())
      .then((data) => {
        setStormData(data.track_datas);
        setMapHovers(data.map_hovers);
        setLoadedDatas(true);
      })
      .catch((error) => setLoadedDatas(true));
  });
  /*const closeOperations = () => {
    setOperationOpen(false);
    window.location.href = "/dashboard";
  };

  const handleOpenOperations = () => {
    setOperationOpen(true);
  };
  const handleCloseOperations = () => setOperationOpen(false);*/

  const [gif, setGif] = useState(false);
  const [file, setFile] = useState(false);
  const [jsonData, setJsonData] = useState<any>();
  const [error, setError] = useState();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [SquallMap, setSquallMap] = useState("");
  const [userLat, setUserLat] = useState(0);
  const [userLon, setUserLon] = useState(0);
  const [PrevLat, setPrevLat] = useState(0);
  const [PrevLon, setPrevLon] = useState(0);
  const [CurLat, setCurLat] = useState(0);
  const [CurLon, setCurLon] = useState(0);
  const [init, setInit] = useState<any>();
  const [storm, setStorm] = useState<any>();
  const [intensity, setIntensity] = useState<any>();
  const [fc, setFc] = useState<any>();

useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_IP}/api/getcsv/${localStorage.fid}`
        );
        if (response.status === 404) {
          setTitle(
            "There are currently no severe warnings active for this location"
          );
        } else {
          const csvText = response.data;
          setJsonData(csvText);
          setTitle(csvText[0]["Squall Header"]);
          setDesc(csvText[0]["Squall Advisory"]);
          setSquallMap(csvText[0]["SatImgSection"]);
          setUserLat(csvText[0]["Client's lat"]);
          setUserLon(csvText[0]["Client's lon"]);
          setCurLat(csvText[0]["Previous Sat lat"]);
          setCurLon(csvText[0]["Previous Sat lon"]);
          setPrevLat(csvText[0]["Latest Sat lat"]);
          setPrevLon(csvText[0]["Latest Sat lon"]);
          setInit(csvText[0]["Initial Heading"]);
          setStorm(csvText[0]["Storm Speed"]);
          setIntensity(csvText[0]["Squall Intensity"]);
          setFc(csvText[0]["Forecaster"]);
        }
      } catch (error: any) {
        setError(error.message || "An error occurred while fetching data");
      }
    };
    fetchData();
  }, []);

  return (
    <div className={open ? "sideNavOpen" : "sideNavClose"}>
      <Box className="fug-container bg-default flex sidenav-full">
        <div className="content-wrap dashboard">
          <Main open={open} className={"main"}>
            <Grid container spacing={2} display={"flex"} alignItems={"center"}>
              <Grid xs={12} md={9}>
                <Grid container spacing={3}>
                  <CardPlan />
                </Grid>
              </Grid>
             {/* {operationOpen && (
                <OperationsComponent
                  isAdmin={isAdmin}
                  open={operationOpen}
                  close={closeOperations}
                />
              )}*/}
              <Grid xs={12} md={3} marginTop={"-10px"} height={"63vh"}>
                <Card
                  style={{
                    height: "150%",
                    borderRadius: "15px",
                  }}
                >
                  {/*<div>
                    <Button
                      variant="contained"
                      onClick={handleOpenOperations}
                      className="operations-button"
                    >
                      CHANGE LOCATION
                    </Button>
                  </div>*/}
                  {/*<Grid xs={12} sx={{ height: 130 }} component="div">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                    >
                      <b>Active Warnings</b>
                    </Typography>
                  </Grid>*/}
                  <Grid xs={12} sx={{ height: 100 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                      fontWeight="bold"
                    >
                      Squall Warnings
                    </Typography>
                    {!jsonData || jsonData.length === 0 ? (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        There are currently no severe warnings active for this
                        location
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="red"
                        align="center"
                        fontWeight="light"
                      >
                        Squall is Active
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} sx={{ height: 130 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      style={{ textAlign: "center" }}
                      fontWeight="bold"
                    >
                      Tropical Cyclone Warnings
                    </Typography>
                    {stormData &&
                    stormData.some((data: any) => data.status_id === 8) ? (
                      stormData.map(
                        (data: any) =>
                          data.status_id === 8 &&
                          mapHovers[`storm_${data.storm_track_id}`] !==
                            undefined &&
                          mapHovers[`storm_${data.storm_track_id}`].lon !==
                            undefined && (
                            <Typography
                              key={data.storm_track_id}
                              className={`blink-text-red`}
                              style={{
                                textAlign: "center",
                                paddingBlock: "5px",
                                color: "red",
                              }}
                            >
                              {data.storm_name === undefined
                                ? ""
                                : `${data.storm_name} - ${data.created_on
                                    .split("-")
                                    .slice(1, 3)
                                    .join("/")}`}
                            </Typography>
                          )
                      )
                    ) : (
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "center",
                          paddingBlock: "5px",
                          color: "green",
                        }}
                      >
                        No Typhoon Warnings
                      </Typography>
                    )}
                  </Grid>
<Grid item xs={12} sx={{ height: 100 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      align="center"
                    >
                      <b>Tropical Advisory</b>
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Main>
        </div>
      </Box>
    </div>
  );
}

